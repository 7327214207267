// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-styleguide-colors-js": () => import("./../../../src/pages/styleguide/colors.js" /* webpackChunkName: "component---src-pages-styleguide-colors-js" */),
  "component---src-pages-styleguide-elements-js": () => import("./../../../src/pages/styleguide/elements.js" /* webpackChunkName: "component---src-pages-styleguide-elements-js" */),
  "component---src-pages-styleguide-logos-js": () => import("./../../../src/pages/styleguide/logos.js" /* webpackChunkName: "component---src-pages-styleguide-logos-js" */),
  "component---src-pages-styleguide-typography-js": () => import("./../../../src/pages/styleguide/typography.js" /* webpackChunkName: "component---src-pages-styleguide-typography-js" */)
}

